import React, { useState } from 'react'
import { Link } from 'gatsby'

function MobileMenu({ menuLinks, location, currentPage })
{
	// This is used for detecting the 'active' page in the sub-menu below
	const currentURL = '/' + location + (currentPage === 'location' ? '' : '/' + currentPage);

	const [showSubmenu, setShowSubmenu] = useState(() => {
		// Iterate through our first batch of submenu items, which should always be an array of locations
		// Compare the URL of that location matches our props.location, then we want to expand the menu by default!
		const filtered = menuLinks[0].items.filter(item => {
			return item.link === '/' + location
		})

		if (filtered[0] != null)
			return { [filtered[0].name]: true }
		else
			return {}
	});

	const handleSubmenuToggle = (key) => {
		setShowSubmenu({ [key]: !showSubmenu[key] });
	};

	return (
  <nav>
    {menuLinks.map(link =>
      link.items ? (
        <React.Fragment key={link.name}>
          <Link className="link depth-0">
            {link.name}
          </Link>
          <span className="indent-0"></span>
          {link.items.map(sublink =>
            sublink.items ? (
              <React.Fragment key={sublink.name}>
				{/* eslint-disable-next-line */}
                <a className="link depth-1" onClick={() => handleSubmenuToggle(sublink.name)}>
                  {sublink.name}
                </a>
                {showSubmenu[sublink.name] && sublink.items.map(nestedsublink => (
                  <div key={nestedsublink.name}>
                    <Link className={"link depth-2" + (currentURL === nestedsublink.link ? ' active' : '')} to={nestedsublink.link}>
                      {nestedsublink.name}
                    </Link>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <div key={sublink.name}>
                <Link className="link depth-1" to={sublink.link}>
                  {sublink.name}
                </Link>
              </div>
            )
          )}
        </React.Fragment>
      ) : (
        <div key={link.name}>
          <Link className="link depth-0" to={link.link}>
            {link.name}
          </Link>
        </div>
      )
    )}
  </nav>
	)
}

export default MobileMenu