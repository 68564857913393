import React from 'react'
// import LocationsAndHours from '../components/LocationsAndHours'
import SocialMediaLinks from './SocialMediaLinks'
import Copyright from './Copyright'

const AppFooter = props => (
	<footer id="footer">
		<section id="locationsandhours" className="style1 bottom">
			{/* {!props.hideLocationAndHours && <LocationsAndHours /> } */}
			<SocialMediaLinks />
			<Copyright />
		</section>
	</footer>
)

export default AppFooter