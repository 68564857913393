import React from 'react'
import { Link } from 'gatsby'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

import menuLogo from '../assets/images/logo_small.png'
import mobileMenuLogo from '../assets/images/logo_small.png'


const timeoutLength = 300

class AppHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      mobileActiveClass: '',
      mouseOverButton: false,
      mouseOverMenu: false,
      mouseOverSubButton: false,
      mouseOverSubMenu: false,
	  width: 0,
	  height: 0,
    }

	this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  	// Stolen from https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
	componentDidMount()
	{
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions()
	{
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        // set the class for the mobile menu
        this.state.active
          ? this.setState({
              mobileActiveClass: 'navPanel-visible',
            })
          : this.setState({
              mobileActiveClass: '',
            })
      }
    )
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState)
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    }
  }

  enterButton = () => {
    this.setState({ mouseOverButton: true })
  }

  leaveButton = () => {
    setTimeout(() => {
      this.setState({ mouseOverButton: false })
    }, timeoutLength)
  }

  enterMenu = () => {
    this.setState({ mouseOverMenu: true })
  }

  leaveMenu = () => {
    setTimeout(() => {
      this.setState({ mouseOverMenu: false })
    }, timeoutLength)
  }

  enterSubButton = () => {
    this.setState({ mouseOverSubButton: true })
  }

  leaveSubButton = () => {
    setTimeout(() => {
      this.setState({ mouseOverSubButton: false })
    }, timeoutLength)
  }

  enterSubMenu = () => {
    this.setState({ mouseOverSubMenu: true })
  }

  leaveSubMenu = () => {
    setTimeout(() => {
      this.setState({ mouseOverSubMenu: false })
    }, timeoutLength)
  }

  render() {
    const siteTitle = this.props.siteTitle
	const menuLinks = this.props.menuLinks
	const location = this.props.location
	const currentPage = this.props.currentPage
    // const open = this.state.mouseOverButton || this.state.mouseOverMenu
	// const subOpen = this.state.mouseOverSubButton || this.state.mouseOverSubMenu
	// const needShortenedMenu = this.state.width < 900

	/* eslint-disable */
    return (
      <React.Fragment>
        <div className={`navbar-menu ${this.state.mobileActiveClass}`}>
          <div id="titleBar">
            <div
              role="button"
			  tabIndex="0"
              onClick={() => this.toggleHamburger()}
              onKeyDown={() => this.toggleHamburger()}
              className="toggle"
              aria-label="Open mobile menu"
            ></div>
            <span className="title">
              <Link to="/"><img src={mobileMenuLogo} alt={siteTitle} /></Link>
            </span>
          </div>
          <div id="navPanel">
            <MobileMenu siteTitle={siteTitle} menuLinks={menuLinks} location={location} currentPage={currentPage} />
          </div>
        </div>

        <header id="header">
          <h1 id="logo">
            <Link to="/"><img src={menuLogo} alt={siteTitle} /></Link>
          </h1>
		  <DesktopMenu siteTitle={siteTitle} menuLinks={menuLinks} location={location} currentPage={currentPage} />
        </header>
      </React.Fragment>
    )
  }
}

export default AppHeader