import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import '../assets/scss/main.scss'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'

const Layout = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            menuLinks {
              name
              link
              items {
                link
                name
                picture
                items {
                  link
                  name
                }
              }
              cl
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
    {/* TODO: Fix this! If you don't use "landing" CSS class all the time, it breaks! */}
        <div className={props.location === '/' || props.location === 'gallery' ? 'landing fadein' : 'landing'}>
          <div id="page-wrapper" className={props.className ?? '' }>
            <AppHeader
              location={props.location}
              currentPage={props.currentPage}
              menuLinks={data.site.siteMetadata.menuLinks}
              siteTitle={data.site.siteMetadata.title}
            />
            {children}
            <AppFooter hideLocationAndHours={props.hideLocationAndHours} />
          </div>
        </div>
      </React.Fragment>
    )}
  />
)

export default Layout
