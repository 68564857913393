import React, { useState } from 'react'
import { Link } from 'gatsby'

function DesktopMenu({ menuLinks, location, currentPage })
{
	// This is used for detecting the 'active' page in the sub-menu below
	const currentURL = '/' + location + (currentPage === 'location' ? '' : '/' + currentPage);

	const [showLocations, setShowLocations] = useState(() => {
		return false;
	});

	const handleLocationsShow = (event) => {
		event.preventDefault();
		setShowLocations(true);
	};

	const handleLocationsHide = () => {
		setShowLocations(false);
	};

	// Build our pull-down Locations menu
	const locations = menuLinks.map((link) => {
		if (!link.items)
			return null;

		return link.items.map(location => (
				<div key={link.name + location.name} className="location">
					{location.picture &&
						<img className="location" src={location.picture} alt={location.name} />
					}
					<h3>
						<Link to={location.link}>
							{location.name}
						</Link>
					</h3>
					<div className="pages">
						{location.items.map(nestedsublink => (
							<Link key={link.name + location.name + nestedsublink.name} className={currentURL === nestedsublink.link ? 'active' : null} to={nestedsublink.link}>
								{nestedsublink.name}
							</Link>
						))}
					</div>
				</div>
			))
	});

	return (
		<div className="navbar-menu">
			<nav>
				<ul>
					{menuLinks.map(link => link.items 
					? (
						<li key={link.name}>
							<Link className="link depth-0" to={link.link} onClick={(e) => handleLocationsShow(e)}>
								{link.name}
							</Link>
						</li>
					) : (
						<li key={link.name}>
							<Link className={link.link === '/visit' ? 'button primary' : null} to={link.link}>
								{link.name}
							</Link>
						</li>
					)
					)}
				</ul>
			</nav>
			<section className={showLocations ? "navbar-locations active" : "navbar-locations"}>
				{locations}
				{/* eslint-disable-next-line */}
				<div className="navbar-shadow" onClick={() => handleLocationsHide()} />
			</section>
		</div>
	)
}
export default DesktopMenu

/* Old version of the menu that showed a small popup window
function DesktopMenu({ menuLinks, location, currentPage })
{
	// This is used for detecting the 'active' page in the sub-menu below
	const currentURL = '/' + location + (currentPage === 'location' ? '' : '/' + currentPage);

	const [showSubmenu, setShowSubmenu] = useState(() => {
		// Iterate through our first batch of submenu items, which should always be an array of locations
		// Compare the URL of that location matches our props.location, then we want to expand the menu by default!
		const filtered = menuLinks[0].items.filter(item => {
			return item.link === '/' + location
		})

		if (filtered[0] != null)
			return { [filtered[0].name]: true }
		else
			return {}
	});

	const handleSubmenuShow = (key, submenukey) => {
		const newState = (submenukey ? { [key]: true, [submenukey]: true } : { [key]: true })
		setShowSubmenu(newState);
	};

	const handleSubmenuHide = () => {
		setShowSubmenu({});
	};

	return (
		<div className="navbar-menu">
			<nav>
				<ul>
					{menuLinks.map(link => link.items 
					? (
						<li key={link.name} onMouseEnter={() => handleSubmenuShow(link.name)}>
							<Link className="link depth-0" to={link.link}>
								{link.name}
							</Link>
							<div className={showSubmenu[link.name] ? "shadow active" : "shadow"} onClick={() => handleSubmenuHide()} />
							<ul className={showSubmenu[link.name] ? "menu active" : "menu"}>
								{showSubmenu[link.name] && link.items.map(sublink => sublink.items 
									? (
										<li key={link.name + sublink.name}>
											<a className="link depth-1" onMouseEnter={() => handleSubmenuShow(link.name, sublink.name)}>
												{sublink.name}
											</a>
											<ul className={showSubmenu[sublink.name] ? "submenu active" : "submenu"}>
												{showSubmenu[sublink.name] && sublink.items.map(nestedsublink => (
													<li key={link.name + sublink.name + nestedsublink.name}>
														<Link className={"link depth-2" + (currentURL === nestedsublink.link ? ' active' : '')} to={nestedsublink.link}>
															{nestedsublink.name}
														</Link>
													</li>
												))}
											</ul>
										</li>
									) : (
										<li key={link.name + sublink.name}>
											<Link className="link depth-1" to={sublink.link}>
												{sublink.name}
											</Link>
										</li>
									)
								)}
							</ul>
						</li>
					) : (
						<li key={link.name}>
							<Link className={link.link === '/visit' ? 'button primary' : null} to={link.link}>
								{link.name}
							</Link>
						</li>
					)
					)}
				</ul>
			</nav>
		</div>
	)
}
*/