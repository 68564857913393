import React from 'react'

const SocialMediaLinks = props => (
	<ul className="icons">
		{/* <li>
			<a href="/" className="icon brands alt fa-twitter">
				<span className="label">Twitter</span>
			</a>
		</li>
		<li>
			<a href="/" className="icon brands alt fa-facebook-f">
				<span className="label">Facebook</span>
			</a>
		</li>
		<li>
			<a href="/" className="icon brands alt fa-linkedin-in">
				<span className="label">LinkedIn</span>
			</a>
		</li> */}
		<li>
			<a href="https://instagram.com/ellisislandcoffee" className="icon brands alt fa-instagram">
				<span className="label">Instagram</span>
			</a>
		</li>
		<li>
			<a href="mailto:info@ellisislandcoffee.com" className="icon solid alt fa-envelope">
				<span className="label">Email</span>
			</a>
		</li>
	</ul>
)

export default SocialMediaLinks